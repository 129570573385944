@import "~react-image-gallery/styles/css/image-gallery.css";

.glow-text {
    font-family: 'Dancing Script', cursive;
    font-weight: 600;
    text-shadow: rgba(255, 255, 255, 0.9) 0px 0px 39px;
}

.script {
    font-family: 'Dancing Script', cursive;
}

.fancy-hover,
.button-hover {
    transition: all 300ms ease;
}

.fancy-hover:hover {
    text-shadow: rgba(255, 255, 255, 0.9) 0px 0px 20px;
}

.button-hover:hover {
    box-shadow: 0px 0px 50px 5px rgba(34, 54, 63, 0.9);
}

/* .fancy-border {
    border-image: url('./assets/image-border.jpeg') 2 fill / 10px / 10px stretch;
    -webkit-border-image: url('./assets/image-border.jpeg') 2 fill / 10px / 10px stretch;
    -moz-border-image: url('./assets/image-border.jpeg') 2 fill / 10px / 10px stretch;
} */

.image-gallery-icon:hover {
    color: #deaf48;
}

.image-gallery-thumbnail.active {
    border-color: #deaf48;
}

.image-gallery-thumbnail:hover {
    border-color: #deaf48;
}

.hoverStaff:hover .staffHover {
    opacity: 1;
}